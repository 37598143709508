#about {
  background-image: url("bg-wedding--red-white.2837ac14.jpg");
}

#about .occasions {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

@media (min-width: 1080px) {
  #about .occasions {
    flex-direction: row;
  }
}

#book .section__title {
  margin-bottom: 0;
  padding-top: 1rem;
}

#book .section__title:first-of-type {
  padding-top: 0;
}

#book .section__intro {
  margin-bottom: .5rem;
}

.book__iframe {
  width: 100%;
  max-width: 20rem;
  flex-direction: column;
  align-items: flex-end;
  display: flex;
  position: relative;
}

.page--book .section__content {
  padding-top: 1rem;
}

.page--book .logo {
  width: 8rem;
  height: 4rem;
  opacity: .85;
  margin-bottom: 1rem;
  transition: opacity .2s;
  display: block;
}

.page--book .logo:hover, .page--book .logo:focus {
  opacity: 1;
}

.page--book .logo svg {
  width: 100%;
  height: 100%;
}

.contact-btn {
  --size: 1.75rem;
  --bg-color: var(--c-lodge-pink, pink);
  --opacity: .4;
  z-index: 10;
  position: fixed;
  bottom: .5rem;
  right: .5rem;
}

.contact-btn svg {
  height: calc(var(--size) * .5);
  width: calc(var(--size) * .5);
  fill: var(--c-white, #fff);
  opacity: .8;
  will-change: opacity;
  transition: opacity .2s;
}

.dark-mode .contact-btn__link, .contact-btn__link {
  height: var(--size);
  width: var(--size);
  color: var(--c-white);
  text-transform: uppercase;
  font-family: var(--font-display);
  will-change: background-color, border-color;
  background-color: #0000004d;
  border: 2px solid #fff9;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  text-decoration: none;
  transition-property: background-color, border-color;
  display: flex;
  box-shadow: 0 2px 8px 2px #1a1a1a66;
}

.dark-mode .contact-btn__link:hover, .dark-mode .contact-btn__link:focus, .contact-btn__link:hover, .contact-btn__link:focus {
  border-color: var(--c-lodge-black);
  background-color: var(--c-light1);
}

.dark-mode .contact-btn__link:hover svg, .dark-mode .contact-btn__link:focus svg, .contact-btn__link:hover svg, .contact-btn__link:focus svg {
  opacity: 1;
  fill: var(--c-lodge-black);
}

.contact-btn__text {
  white-space: nowrap;
  z-index: -1;
  height: 1rem;
  text-shadow: 0 0 1px #000, 0 0 2px #000c, 0 0 4px #00000080;
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  padding: 0 .5rem;
  transition-property: transform;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, 0, 1.2);
  display: flex;
  position: absolute;
  overflow: hidden;
  transform: translateX(0)scale(0);
}

.contact-btn__link:hover .contact-btn__text, .contact-btn__link:focus .contact-btn__text {
  transform: translateX(-65%)scale(1);
}

.contact__form {
  max-width: 12rem;
  width: 100%;
}

.contact__details {
  max-width: 28rem;
}

.contact__info {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.contact__col-content {
  height: 2.5rem;
  font-size: 1.3em;
}

.contact__col:first-of-type .contact__col-content {
  font-size: 1em;
  line-height: .75rem;
}

.contact__phone {
  --c-link: var(--c-dark3);
  align-items: center;
  display: inline-flex;
}

.contact__phone svg {
  height: 1em;
  width: 1em;
  fill: currentColor;
}

@media (min-width: 780px) {
  .contact__info {
    flex-direction: row;
  }

  .contact__col {
    flex: 50%;
  }
}

.faqs {
  color: var(--c-text-dark, #0f0f0f);
  backdrop-filter: blur(.125rem);
  width: 100%;
  max-width: 22rem;
  background-color: #ffffffb3;
  padding: 1rem 1rem 2rem;
  font-size: 1.2em;
}

.faqs__intro {
  font-size: .8em;
  font-family: var(--font-display);
  text-transform: uppercase;
  color: var(--c-dark5);
  justify-content: center;
  font-weight: 400;
  display: flex;
}

.faq {
  margin-top: 1rem;
}

.faq:first-child, .faq h4 + p {
  margin-top: 0;
}

.faq__summary {
  font-size: 1.1em;
  font-family: var(--font-body, serif);
  text-transform: initial;
  padding-left: .5rem;
  font-weight: 600;
  line-height: .5rem;
  display: inline;
  position: relative;
  top: .125rem;
}

.faq__details {
  padding: .5rem 0;
}

.faq__details h4 {
  color: var(--c-dark4, #888);
}

@media (min-width: 640px) {
  .faqs {
    padding: 1.5rem 2rem 2.5rem;
  }

  .faq__summary {
    font-size: 1.2em;
    line-height: 1rem;
  }

  .faq__details {
    padding: .5rem 1rem 1rem;
  }
}

.follow {
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
}

.follow__title {
  text-align: center;
  margin-bottom: .5rem;
}

.footer {
  background-color: var(--c-white, #fff);
  color: var(--c-text-dark, #0f0f0f);
  justify-content: center;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.footer__logo {
  height: 3rem;
}

.footer__logo svg {
  width: 100%;
  height: 100%;
}

.footer__text {
  height: 5rem;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  line-height: 1.3;
  display: flex;
}

.footer__text > div + div {
  margin-top: .5rem;
}

.footer__text a:before {
  content: "";
  display: block;
}

@media (min-width: 640px) {
  .footer__content {
    align-items: center;
    gap: 1rem;
    display: flex;
  }

  .footer__text {
    height: 3rem;
    text-align: left;
    padding-top: .5rem;
  }

  .footer__text > div + div {
    margin-top: 0;
  }

  .footer__text a:before {
    display: none;
  }
}

.logo {
  width: 8rem;
  height: 4rem;
  opacity: .85;
  transition: opacity .2s;
  display: block;
}

.logo:hover, .logo:focus {
  opacity: 1;
}

.logo svg {
  width: 100%;
  height: 100%;
}

.services .card__title {
  height: 1.5rem;
  background-color: var(--c-dark5);
}

.services .card__title:first-child {
  height: 2rem;
  background-color: var(--c-info, blue);
}

.services .card__content {
  border: 0;
  margin-top: 0;
  padding: 0;
}

.services .services__inclusions {
  padding: 1rem 1rem 2rem;
}

.services__diy {
  text-align: center;
  border: 1px solid var(--c-border, #ccc);
  border-top: 0;
  padding: 1rem;
  font-size: 1.2em;
}

.services__diy .service__number {
  color: var(--c-lodge-purple);
  margin-top: -.1em;
  font-weight: bold;
}

.services__addons .service {
  flex-direction: column;
  display: flex;
}

.services__addons .service .service__price {
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  display: flex;
}

.services__inclusions-list h4:not(:first-child) {
  margin-top: 1rem;
}

.services__wrap {
  border-bottom: 1px solid var(--c-border, #ccc);
}

.service {
  text-align: center;
  padding: 1rem;
}

.service + .service {
  border-top: 1px solid var(--c-border, #ccc);
}

.services__cta {
  justify-content: center;
  padding: 1rem 1rem 0;
}

.service__title {
  color: var(--c-lodge-black, #222);
  font-size: 1.5em;
}

.service__time {
  color: var(--c-dark5, #555);
}

.service__price {
  color: var(--c-lodge-black, #222);
}

.service__number {
  color: var(--c-dark1, #111);
  font-size: 2.5em;
  line-height: 1.5rem;
  position: relative;
}

.service__number ~ .service__number {
  font-size: 1.8em;
  line-height: 1rem;
}

.service__or {
  color: var(--c-lodge-black);
  font-size: .6em;
  position: absolute;
  transform: translateX(-120%);
}

@media (min-width: 520px) {
  .services__diy {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .services__diy span {
    align-items: center;
    display: flex;
  }
}

@media (min-width: 800px) {
  .services__wrap {
    flex-wrap: wrap;
    display: flex;
  }

  .services__diy {
    flex-direction: row;
  }

  .services__addons {
    flex-wrap: nowrap;
    display: flex;
  }

  .service {
    flex: 50%;
  }

  .service + .service {
    border-top: 0;
    border-left: 1px solid var(--c-border, #ccc);
  }

  .service:nth-child(3), .service:nth-child(4) {
    border-top: 1px solid var(--c-border, #ccc);
  }

  .services__inclusions-list {
    gap: 1rem;
    display: flex;
  }
}

@media (min-width: 1080px) {
  .services .services__inclusions {
    padding: 1rem 2rem 2rem;
  }

  .services__wrap {
    flex-wrap: nowrap;
  }

  .service {
    flex: 1;
  }

  .service + .service {
    border-top: 0;
  }
}

@media (min-width: 1200px) {
  .services .services__inclusions-list {
    gap: 2rem;
  }
}

#top {
  background-image: url("bg-main.9adb5273.jpg");
  background-position: 66% 0;
}

#top .section__content {
  padding-top: 1rem;
}

#top .section__title {
  text-align: center;
  margin-bottom: 0;
  font-size: 2em;
  line-height: 1.5rem;
}

#top .follow {
  margin-top: 10rem;
}

@media (min-width: 640px) {
  #top .section__title {
    margin-top: 7rem;
    font-size: 3em;
  }
}

.vendors {
  width: 100%;
  max-width: 100%;
  flex-direction: column;
  display: flex;
}

.vendors__list {
  box-sizing: border-box;
  grid-gap: 1rem;
  width: 100%;
  grid-template-columns: repeat(auto-fill, 100%);
  margin-top: 1rem;
  display: grid;
}

.vendor {
  height: 14rem;
  cursor: pointer;
  backdrop-filter: blur(.125rem);
  color: var(--c-text-dark, #0f0f0f);
  background-color: #fffc;
  border-radius: 2px;
  flex-direction: column;
  padding: 0;
  text-decoration: none;
  transition: background-color .4s, outline .4s;
  display: flex;
  box-shadow: 0 1px 3px #00000080;
}

.vendor:hover, .vendor:focus-within {
  outline: 3px solid var(--c-lodge-pink, pink);
  background-color: var(--c-white);
}

.vendor__header {
  padding: 1rem 1rem .5rem;
}

.vendor__name {
  font-size: 1.5em;
}

.vendor__tags {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.vendor__image {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-color: var(--c-dark2, #ccc);
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.vendor__details {
  width: 100%;
  padding: .5rem;
}

.vendor__details .btn {
  --color: var(--c-dark3, #aaa);
  --hover-color: var(--c-lodge-purple, purple);
  text-align: center;
  flex-grow: 1;
  padding-left: 0;
  padding-right: 0;
  font-size: 1.2em;
  display: block;
}

.vendors__empty {
  margin: 0 auto;
  display: none;
}

.vendors__empty .vendor__details {
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 640px) {
  .vendors__filter {
    width: 50%;
    align-self: center;
  }

  .vendors__list {
    grid-template-columns: repeat(auto-fill, calc(50% - .25rem));
    gap: .5rem;
  }
}

@media (min-width: 990px) {
  .vendors__filter {
    width: calc(33% - .25rem);
    align-self: end;
  }

  .vendors__list {
    grid-template-columns: repeat(auto-fill, calc(33.3333% - .3333rem));
  }
}

@media (min-width: 1080px) {
  .vendors__filter {
    width: calc(33% - .5rem);
  }

  .vendors__list {
    grid-template-columns: repeat(auto-fill, calc(33.3333% - .6666rem));
    gap: 1rem;
  }
}

@media (min-width: 1280px) {
  .vendor__details {
    padding: 1rem;
  }
}

#services {
  background-image: url("bg-amenities.71a87ba0.jpg");
}

#vendors {
  background-image: url("bg-wedding--bright-cake.c765bb98.jpg");
}

#faq {
  background-image: url("bg-just-married.a3fa6c26.jpg");
}

#book {
  background-image: url("bg-exterior.38ace956.jpg");
}

#contact {
  background-image: url("bg-contact.638e7038.jpg");
}

/*# sourceMappingURL=index.e017f5fc.css.map */
